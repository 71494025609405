import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label
} from 'recharts';

function GoldData() {
    const [hasError, setErrors] = useState(false);
    const [goldPrices, setGoldPrices] = useState([{ time: 'pepe', value: 'paco' }]);
    const [loading, setLoading] = useState(false);

  
    useEffect(() => {
        fetch("https://www.quandl.com/api/v3/datasets/LBMA/GOLD/data.json?collapse=annual&api_key=" + process.env.GATSBY_API_KEY)
        .then(res => res.json())
        .then(res => {
          setGoldPrices(res.dataset_data.data.map((data) => ({time: data[0].slice(0,4), value: data[2]})));
          setLoading(true);
        })
        .catch((e) => {
          setErrors('fetch failed');
        });
    }, []);


    return (

      (loading) ? 
      //goldData.dataset_data.data.map((data, index) => <div key={index}>Year : {data[0]} Price: {data[2]}</div>)
      <ResponsiveContainer width="100%" height={400}>
       <LineChart
         data={goldPrices}
         margin={{
           top: 10, right: 20, left: 20, bottom: 5,
         }}
       >
         <CartesianGrid strokeDasharray="3 3" />
         <XAxis dataKey="time" reversed={true} />
         <YAxis type="number" domain={[0, 2000]} orientation="right" unit="€" interval={0} tickCount={10} />
         {/* ticks={[100, 120, 140, 160, 180]}*/}
         <Tooltip />
         <Legend />
         <Line type="monotone" dataKey="value" name="Precio Oro" stroke="#8884d8" activeDot={{ r: 8 }}/>
       </LineChart>
       </ResponsiveContainer>
/*       <>
      <div>HELLO</div>
      <div>{goldPrices[1].value}</div>
      </> */
      :
      <div className="text-center">
      <Spinner color="secondary" />
      </div>
      )

  };
  
  export default GoldData;