import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label
} from 'recharts';



function SilverDataDaily() {
  const [hasError, setErrors] = useState(false);
  const [silverPrices, setSilverPrices] = useState([{ time: '', value: '' }]);
  const [loading, setLoading] = useState(false);
  const [silverPriceDaily, setSilverPriceDaily] = useState([{ time: '', value: '' }]);

    let nDt = new Date()
    let date = nDt.getDate();
    let monthCurrent = nDt.getMonth() + 1;
    let year = nDt.getFullYear();
    let separator = '-'

    let monthPrevious = nDt.getMonth();

    let originDate = `${year}${separator}${monthPrevious}${separator}${date}`
    let currentDate = `${year}${separator}${monthCurrent}${separator}${date}`
    

    //"https://www.quandl.com/api/v3/datasets/LBMA/GOLD/data.json?start_date=2020-06-01&end_date=2020-06-26&api_key=93Z2XzedZYbnYfqYEqur"
    useEffect(() => {
        fetch("https://www.quandl.com/api/v3/datasets/LBMA/SILVER/data.json?start_date="+`${originDate}`+"&end_date="+`${currentDate}`+"&api_key=" + process.env.GATSBY_API_KEY)
        .then(res => res.json())
        .then(res => {
          setSilverPrices(res.dataset_data.data.map((data) => ({time: data[0].slice(-5), value: data[3]})));
          setSilverPriceDaily(res.dataset_data.data.map((data) => ({time: data[0] , value: data[2]})).slice(1));
          setLoading(true);
        })
        .catch((e) => {
          setErrors('fetch failed');
        });
    }, []);


    return (

      (loading) ? 
      <>
      
        <div>
        Precio <strong>onza de plata</strong> a día {silverPriceDaily[0].time} : {silverPriceDaily[0].value.toFixed(3)}€ <br />
        Precio <strong>gramo de plata</strong> a día {silverPriceDaily[0].time} : {(silverPriceDaily[0].value/31.1034768).toFixed(3)}€
        </div>
      <ResponsiveContainer width="100%" height={400}>
       <LineChart
         data={silverPrices}
         margin={{
           top: 10, right: 20, left: 20, bottom: 5,
         }}
       >
         <CartesianGrid strokeDasharray="3 3" />
         <XAxis dataKey="time" reversed={true} />
         <YAxis type="number" domain={['dataMin - 2', 'dataMax + 2']} orientation="right" unit="€" interval={0} tickCount={2} />
         {/* ticks={[100, 120, 140, 160, 180]}*/}
         <Tooltip />
         <Legend />
         <Line type="monotone" dataKey="value" name="Precio de la Plata en los últimos 30 días" stroke="#8884d8" activeDot={{ r: 8 }}/>
       </LineChart>
       </ResponsiveContainer>
        </>
      :
      <div className="text-center">
      <Spinner color="secondary" />
      </div>
      )

  };
  
  export default SilverDataDaily;