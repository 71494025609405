// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-politica-cookies-js": () => import("./../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-posts-precio-oro-mdx": () => import("./../src/pages/posts/precio-oro.mdx" /* webpackChunkName: "component---src-pages-posts-precio-oro-mdx" */),
  "component---src-pages-posts-precio-plata-mdx": () => import("./../src/pages/posts/precio-plata.mdx" /* webpackChunkName: "component---src-pages-posts-precio-plata-mdx" */),
  "component---src-pages-posts-tasacion-coches-mdx": () => import("./../src/pages/posts/tasacion-coches.mdx" /* webpackChunkName: "component---src-pages-posts-tasacion-coches-mdx" */),
  "component---src-pages-posts-tasacion-joyas-mdx": () => import("./../src/pages/posts/tasacion-joyas.mdx" /* webpackChunkName: "component---src-pages-posts-tasacion-joyas-mdx" */),
  "component---src-pages-posts-tasacion-monedas-mdx": () => import("./../src/pages/posts/tasacion-monedas.mdx" /* webpackChunkName: "component---src-pages-posts-tasacion-monedas-mdx" */),
  "component---src-pages-posts-tasacion-oro-mdx": () => import("./../src/pages/posts/tasacion-oro.mdx" /* webpackChunkName: "component---src-pages-posts-tasacion-oro-mdx" */),
  "component---src-pages-posts-tasacion-vivienda-mdx": () => import("./../src/pages/posts/tasacion-vivienda.mdx" /* webpackChunkName: "component---src-pages-posts-tasacion-vivienda-mdx" */),
  "component---src-template-single-post-js": () => import("./../src/template/single-post.js" /* webpackChunkName: "component---src-template-single-post-js" */)
}

